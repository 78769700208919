import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseRequest } from '../config/axiosClient';
import { handleFetch, isDefined } from '../config/helpers';
import useSafePush from './useSafePush';

const useAuthCheck = () => {

    const dispatch = useDispatch();
    const router = useRouter();
    const {safePush} = useSafePush();
    const redirect_reason = useSelector(state => state.authReducer.redirect_reason);

    const authenticationCheck = async (redirect = false, setValue = null) => {
        const authCheck = await handleFetch(getBaseRequest('user'));
        if(authCheck?.status == 'failed') {
            if(redirect){
                const base_route = router.pathname == process.env.NEXT_PUBLIC_USER_LOGIN_URL ? process.env.NEXT_PUBLIC_USER_LOGIN_URL : `${process.env.NEXT_PUBLIC_USER_LOGIN_URL}?origin_url=${encodeURIComponent(router.asPath)}`
                const redirect_str = redirect_reason != "" && isDefined(redirect_reason) ? `${process.env.NEXT_PUBLIC_USER_LOGIN_URL}?redirect=${redirect_reason}` : base_route;
                safePush(redirect_str);
            }
        }
        if(typeof setValue == 'function'){
            setValue(true);
        }
        dispatch({
            type: 'AUTH_SET_USER',
            payload: {
                user: authCheck.user,
                linkedAccounts: authCheck.linkedAccounts,
                defaultAccount: authCheck.defaultAccount,
                loadUser: !(router.query.expires && router.query.expires)
            }
        });
    }

    return {authenticationCheck}
}

export default useAuthCheck;