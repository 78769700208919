import { getBaseRequest, portalRoutes } from '@components/config/axiosClient';
import { isDefined } from '@components/config/helpers';
import CacheFactory from '@components/Factories/CacheFactory';
import userActions from '@components/Redux/User/actions';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAlert from '../useAlert';
import useAuthCheck from '../useAuthCheck';
import useFetch from '../useFetch';
import useGlobalSettings from '../useGlobalSettings';
import useSafePush from '../useSafePush';

const useUserInit = () => {
    const dispatch = useDispatch();
    const router = useRouter();
    const { topMiddleAlert } = useAlert();
    const {authenticationCheck} = useAuthCheck();
    const {getSetting} = useGlobalSettings();
    const {safePush} = useSafePush();
    const {get} = useFetch();

    const cart = useSelector(state => state.cartManagementReducer.cart);
    const agent = useSelector(state => state.globalReducer.agent);
    const token = useSelector(state => state.userReducer.token);
    const user = useSelector(state => state.authReducer.user);
    const ip = useSelector(state => state.userReducer.ip);
    const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
    const redirect_reason = useSelector(state => state.authReducer.redirect_reason);

    useEffect(() => {
        if (user && isAuthenticated) {
            const hasRoute = portalRoutes.some(route => router.pathname.startsWith(route));
            if (hasRoute) {
                getBaseRequest('check-session').then(res => {
                    if (!res?.data?.logged_in) {
                        dispatch({ type: 'AUTH_LOGOUT_SUCCESS', payload: { reason: 'force_logout' } });
                        topMiddleAlert('ERROR', 'You have been logged out');
                    }
                });
            }
        }
    }, [router])

    useEffect(() => {
        if(redirect_reason == 'logout') return;
        const portalStatus = getSetting('portal_status');
        const currentRoute = router.pathname;
        const isOnRoute = portalRoutes.some(route => router.pathname.startsWith(route));

        const checkIp = async () => {
            const  ip_check = await get(`check-ip?ip=${ip}`);
            if(!ip_check.allowed){
                safePush(process.env.NEXT_PUBLIC_HOME_URL)
            }
        }
        if(ip != null){
            if((portalStatus == 'enabled') && (
                !isOnRoute || currentRoute.startsWith('/user')
            )
            ) {
                authenticationCheck(false)
            }
            
            if(isOnRoute && portalStatus == 'disabled'){
                checkIp();
            }
        }

      
    }, [ip])

    useEffect(() => {
        if(typeof window != 'undefined'){
            dispatch({
                type: userActions.USER_INITIALIZE_DATA,
                payload: { user_agent: agent.user_agent, isMobile: agent.isMobile },
            });
        }
    }, [])

    useEffect(() => {
        isDefined(cart) && cart.counter > 0 && token == null && dispatch({
            type: userActions.SET_TOKEN,
            payload: {
                requestType: 'fresh'
            }
        })
    }, [cart])

    useEffect(() => {
      isDefined(CacheFactory.get('token')) && dispatch({
        type: userActions.SET_TOKEN,
        payload: {
            token: CacheFactory.get('token')
        }
      })
    }, [])


    return {}

}

export default useUserInit;