import { useDispatch } from "react-redux";

const useAlert = () => {
    const dispatch = useDispatch();

    const topMiddleAlert = (type, message) => {
        dispatch({
          type: `TOP_MIDDLE_${type}_ALERT`,
          payload: message
        })
    }

    const customAlert = (message, severity = 'info', variant = 'filled', duration = 3000, vertical = 'top', horizontal = 'center') => {
      dispatch({
          type: `CUSTOM_ALERT`,
          payload: {
              title: message,
              severity: severity,
              variant: variant,
              duration: duration,
              vertical: vertical,
              horizontal: horizontal,
          }
        })
    }

    const handleAlertResponse = (response, successCallback = () => null, failCallback = () => null) => {
      if(response?.status == 'success'){
          topMiddleAlert('SUCCESS', response.message);
          if(typeof successCallback == 'function'){
            successCallback();
          }
      }
      else if(response?.status == 'failed'){
          topMiddleAlert('ERROR', response.message);
          if(typeof failCallback == 'function'){
            failCallback();
          }
      }
      else if(response?.status == 'warning'){
          topMiddleAlert('WARNING', response.message);
          if(typeof failCallback == 'function'){
            failCallback();
          }
      }
    }
    
    return {
      topMiddleAlert, 
      customAlert,
      handleAlertResponse
    }
}

export default useAlert;