import { isDefined } from '@components/config/helpers';
import CacheFactory from '@components/Factories/CacheFactory';
import userJourneyActions from '@components/Redux/UserJourney/actions';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from './useAuth';

const useUserJourney = (utm_data) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const userJourney = useSelector(state => state.userjourneyReducer.userJourney);
    const brand = useSelector(state => state.globalReducer.brand);
    const user_agent = useSelector(state => state.globalReducer.agent.user_agent);
    const currentPath = router.asPath;
    const {isAgent} = useAuth();

    const isOurUserAgent = () => {
        return user_agent == 'MillMollAPI/1.0';
    }

    //check  if userJourney in cache,
    useEffect(() => {
        // Is our user agent don't set user journey;
        if(isOurUserAgent()) return;
        //if so set userJourney using the saved userJourney
        if (
            isDefined(CacheFactory.get("userJourney")) &&
            CacheFactory.get("userJourney")["user-journey-id"] != null
        ) {
            dispatch({
                type: userJourneyActions.USERJOURNEY_SET,
                payload: CacheFactory.get("userJourney"),
            });
            //else create new userJourney using api
        } else {
            dispatch({
                type: userJourneyActions.USERJOURNEY_SAVE_DATA,
                payload: { brand: brand.brand_key, ...utm_data },
            });
        }
    }, []);

    //if the userJourney has changed or if the currentPath has changed
    //check if thecurrentPath is in the array of urls the user visited.
    //if it has not been added then save the user journey entry in the db using the action
    useEffect(() => {
        if(isOurUserAgent()) return;
        var save_entry = false;
        if(isAgent()) return;

        if (isDefined(userJourney) && userJourney["user-journey-id"] != null) {
            if (userJourney.tracking.includes(currentPath) == true) {
                if (userJourney.tracking[userJourney.tracking.length - 1] != currentPath) {
                    save_entry = true;
                }
            } else {
                save_entry = true;
            }
            if (save_entry) {
                dispatch({
                    type: userJourneyActions.USERJOURNEY_ENTRY_SAVE_DATA,
                    payload: {
                        user_journey_id: userJourney["user-journey-id"],
                        url: currentPath,
                    },
                });
            }
        }
    }, [currentPath, userJourney]);

    return {}
}

export default useUserJourney;