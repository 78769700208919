import { isDefined } from '@components/config/helpers';
import CacheFactory from '@components/Factories/CacheFactory';
import globalActions from '@components/Redux/Global/actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useVAT = () => {
    const dispatch = useDispatch();
    const global_settings = useSelector(state => state.globalReducer.global_settings);

    const switchVat = () => {
        dispatch({ type: globalActions.GLOBAL_SWITCH_VAT_TOGGLE }); //function to toggle the vat switch once clicked (true/false)
    };
    
    useEffect(() => {
        const VATFlag = CacheFactory.get("VATFlag"); //grab the vat cache
        isDefined(VATFlag) && switchVat();
        dispatch({
            type: globalActions.GLOBAL_SET_TAX,
            payload: parseInt(global_settings.tax),
        });
    }, []);

    return {switchVat}
}

export default useVAT;